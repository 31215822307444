import React from "react";
import { Link } from "gatsby"
import Img from "gatsby-image"
import "../../assets/scss/components/_bookCard.scss"

const BookCard = ({book}) => (
  <div className={'book__card'}>
    <Link to={`/books/${book.slug}`}>
      <Img fluid={book.featuredImage?.node.localFile.childImageSharp.fluid} alt={book.title} />
      <h2 className={'col-10 title'}>{book.title}</h2>
    </Link>
  </div>
)

export default BookCard
