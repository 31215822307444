import React from "react"
import Img from "gatsby-image"
import Layout from "../../components/layout/layout"
import SEO from "../../components/util/seo"
import ColumnContent from "../../components/content/columnContent"
import Copyright from "../../components/util/copyright"
import "../../assets/scss/layout/pages/author.scss"
import BookCard from "../../components/card/bookCard"
import PreviousNext from "../../components/util/previousNext"

const Book = ({pageContext, path}) => {
  const { ACF, title, content, featuredImage, previous, next } = pageContext;

  return (
    <Layout mt={true} path={path} secondMain={secondMain(ACF, previous, next)}>
      <SEO title={`Author - ${title}`} />
      <div className={'author justify-content-lg-between flex-column flex-lg-row'}>
        <div className={'picture col-4'}>
          <div className={'full__column'}>
            <div className={'sticky'}>
              {featuredImage && (
                <Img fluid={featuredImage.node.localFile.childImageSharp.fluid} title={title} />
              )}
              <div className={'justify-content-lg-between flex-end'}>
                <p className={'author__name d-none d-lg-block'}>{title}</p>
                {ACF?.copyright && <Copyright text={ACF?.copyright} />}
              </div>
            </div>
          </div>
        </div>
        <ColumnContent contentEN={ACF?.content || ''} contentFR={content} title={title} />
      </div>
    </Layout>
  )
}

const secondMain = (ACF, previous, next) => (
  <>
    <div className={'content'}>
      <div className={'container flex-end'}>
        <div className={'testimonial col-8'}>
          <blockquote>
            <cite>{ACF?.citationSource || ""}</cite>
            <p>{ACF?.citation || ""}</p>
          </blockquote>
        </div>
      </div>
    </div>
    <div className={'content-light'}>
      <div className={'container flex-end'}>
        <div className={'featured__books col-8'}>
          {ACF?.featuredBooks && (
            <>
              <h2>Books</h2>
              <div className={'books grid-4'}>
                {ACF.featuredBooks.map((book, index) => <BookCard key={index} book={book} />)}
              </div>
            </>
          )}
          <PreviousNext nextLink={next ? `/authors/${next.slug}` : ""} prevLink={previous ? `/authors/${previous.slug}` : ""} />
        </div>
      </div>
    </div>
  </>
)

export default Book
