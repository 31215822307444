import React from "react"
import PostContent from "./postContent"
import "../../assets/scss/components/_columnContent.scss"

const ColumnContent = ({title, subTitle, contentFR, contentEN, className}) => (
  <div className={`content__column ${className ? className : 'col-5'}`}>
    <div className={'d-flex'}>
      <h1>{title}</h1>
    </div>
    {subTitle && <p className={'subTitle'}>{subTitle}</p>}
    <div className={'d-lg-flex'}>
      <p className={'lang'}>FR</p>
      <PostContent className={'translated__content'} content={contentFR} />
    </div>
    <div className={'d-lg-flex'}>
      <p className={'lang'}>EN</p>
      <PostContent className={'translated__content'} content={contentEN} />
    </div>
  </div>
)

export default ColumnContent
