import React from "react"
import { Link } from "gatsby"
import "../../assets/scss/components/_previousNext.scss"

const PreviousNext = ({prevLink, nextLink}) => (
  <div className={'previous-next justify-content-between'}>
    <Link to={prevLink} className={`previous ${!prevLink && 'disabled'}`}>
      <i className="fas fa-arrow-left" />
      <p>Previous</p>
    </Link>

    <Link to={nextLink} className={`next ${!nextLink && 'disabled'}`}>
      <p>Next</p>
      <i className="fas fa-arrow-right" />
    </Link>
  </div>
)

export default PreviousNext
